<template>
  <BasePage
    :bannerUrl="banner"
    subTilte="文安县城发集团欢迎您"
    content="规范服务 / 阳光清廉 / 示范引领 / 降本增效"
    :hideLeft="true"
  >
    <BaseModular
      subTitle="会员权益一览表"
      title="公司善于捕捉用户需求，利用丰富的行业通理与产品经验打造落地的互联网产品"
    >
      <TopTable />
      <CenterTable />
      <BottomTable />
    </BaseModular>
  </BasePage>
</template>

<script>
import imgData from "@/data";
import { reactive } from "vue";
import BaseModular from "@/components/BaseModular/BaseModular";
import BasePage from "@/components/BasePage/BasePage.vue";
import TopTable from "./components/TopTable/TopTable";
import CenterTable from "./components/CenterTable/CenterTable";
import BottomTable from "./components/BottomTable/BottomTable";
export default {
  components: { BasePage, TopTable, CenterTable, BottomTable, BaseModular },
  setup() {
    const data = reactive({
      ...imgData.EquityImg,
    });

    return {
      ...data,
    };
  },
};
</script>

<style scoped>
</style>

